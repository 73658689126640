.footer-dark {
    padding:50px 0;
    color:black;
    font-weight: 500;
    background-color:white;
  }
  
  .footer-dark h3 {
    margin-top:4rem;
    font-weight:bold;
    font-size:24px;
  }
  
  .footer-dark ul {
    padding:0;
    list-style:none;
    line-height:1.6;
    font-size:18px;
    margin-bottom:0;
    color: black;
  }
  
  .footer-dark ul a {
    color:black;
    text-decoration:none;
    opacity:0.6;
  }
  
  .footer-dark ul a:hover {
    opacity:0.9;
  }
  
  @media (max-width:767px) {
    .footer-dark .item:not(.social) {
      text-align:center;
      padding-bottom:20px;
    }
  }
  
  .footer-dark .item.text {
    margin-bottom:36px;
    font-size: 18px;
  }
  
  @media (max-width:767px) {
    .footer-dark .item.text {
      margin-bottom:0;
    }
  }
  
  .footer-dark .item.text p {
    opacity:1;
    margin-bottom:0;
  }
  
  .footer-dark .item.social {
    text-align:center;
  }
  
  @media (max-width:991px) {
    .footer-dark .item.social {
      text-align:center;
      margin-top:10px;
      margin-bottom: 10px;
    }
  }

  .icon_link {
    margin-bottom: 20px;
  }
  
  .footer-dark .item.social > a {
    font-size:35px;
    width:48px;
    height:48px;
    line-height:45px;
    display:inline-block;
    text-align:center;
    border-radius:30%;
    box-shadow:0 0 0 1px black;
    margin:4px 8px;
    color:black;
    opacity:0.6;
  }
  
  .footer-dark .item.social > a:hover {
    opacity:0.9;
  }
  
  .footer-dark .copyright {
    text-align:center;
    padding-top:24px;
    opacity:0.3;
    font-size:13px;
    margin-bottom:0;
  }

  .footer__logo {
    font-family: 'Pacifico', cursive;
    font-weight: 400;
    font-size: 4rem;
  }

  .center_vertical {
      display: table-cell;
      vertical-align: middle;
  }

@import url('https://kit.fontawesome.com/94771b6ab1.js');