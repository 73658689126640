.terms h1 {
    color: #e95d80;
    padding-bottom: 10px;
    padding-top: 50px;
    font-weight: 700;
    font-size: 40px;
}

.privacy h2 {
    color: #e95d80;
    padding-bottom: "";
    padding-top: 30px;
    font-weight: 600;
    font-size: 36px;
}

.mobile h1 {
    color: #e95d80;
    padding-bottom: 5px;
    padding-top: 25px;
    font-weight: 700;
    font-size: 20px;
}

.mobile p {
    text-align: justify;
    font-size: 12px;
    font-weight: 600;
    padding-top: 12px;
}


.privacy h3 {
    padding-top: 10px;
}

.privacy p {
    text-align: justify;
    font-size: 16px;
    font-weight: 600;
    padding-top: 24px;
}



p {
    text-align: justify;
    font-size: 16px;
    font-weight: 600;
    padding-top: 48px;
}

.heading {
    font-size: 56px;
    text-align: center;
    font-weight: 700;
}

.sub_heading {
    text-align: center;
}

.indent_2 {
    margin-left: 30px;
}

.terms {
    font-style: bold;
}