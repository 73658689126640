body {
    margin: 0;
    background-color: #525659;
    font-family:
        Segoe UI,
        Tahoma,
        sans-serif;
}

.Example input,
.Example button {
    font: inherit;
}

.Example header {
    background-color: #323639;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    padding: 20px;
    color: white;
}

.Example header h1 {
    font-size: inherit;
    margin: 0;
}

.Example__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0;
    padding: 10px;
}

.Example__container__load {
    margin-top: 1em;
    color: white;
}

.Example__container__document {
    width: 90%;
    max-width: calc(100% - 2em);
    margin: 1em 0;
    box-shadow: 0 0 10px #e95d80;
}

.Example__container__document .react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Example__container__document .react-pdf__Page {
    margin: 1em 0;
    box-shadow: 0 0 0px rgba(0, 0, 0, 0.5);
}

.Example__container__document .react-pdf__message {
    padding: 20px;
    color: white;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.example::-webkit-scrollbar {
    display: none;
}

.parent {
    margin: 50px;
    position: relative;
    width: 100%;
    background: hsla(180, 2%, 8%, 0.76);
    color: white;
}

.parent:before {
    content: "";
    width: 100%;
    height: 40px;
    background: linear-gradient(rgba(0, 0, 0, .0), rgba(0, 0, 0, .5), transparent);
    position: absolute;
    top: -10px;
}

.parent::after {
    content: "";
    width: 100%;
    height: 40px;
    background: linear-gradient(transparent, rgba(0, 0, 0, .5), rgba(0, 0, 0, .0));
    position: absolute;
    bottom: -10px;
}

.text {
    overflow-y: auto;
    height: 100%;
}