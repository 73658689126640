@import "~@flaticon/flaticon-uicons/css/all/all";

body {
  font-family: 'Roboto', sans-serif;
}

.custom-style {
  padding-left: 0px;
  z-index: 2;
  width: 100%;
}

.custom-style h1 {
  text-shadow: 3px 3px 5px black;
}

.custom-style p {
  text-shadow: 3px 3px 5px black;
}

@media (max-width: 576px) {
  .custom-style {
    padding: 5%;
    width: 100%;
    margin-left: 0rem;
  }
}

.links .active {
  color: white!important;
  background-color:  #e95d80!important;
  border-radius: 10%;
}

.links .active.gold {
  color:  white!important;
  background-color: #ccac00!important;
  border-radius: 10%;
}

.custom_btn {
  padding-left: 20px!important;
  padding-right: 20px!important;
}