.account-card {
    width: 40rem;
    text-align: center;
    border: none;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}


.account-title {
    font-weight: bold;
    font-size: 32px;
    margin-bottom: 20px;
    color: #e95d80;
}

.account-text {
    font-size: 18px;
    color: #6c757d;
    margin-bottom: 30px;
}

.custom-button {
    color: #333; /* Dark gray text */
    border: none;
    border-radius: 8px;
    padding: 12px 24px;
    transition: all 0.3s ease;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}
.custom-button:hover {
    transform: translateY(1px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.otp-input {
    width: 3rem;
    height: 3rem;
    margin: 0 0.5rem;
    text-align: center;
    font-size: 1.5rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
    transition: box-shadow 0.3s ease;
}
.otp-input:focus {
    box-shadow: 0 0 5px rgba(233, 93, 128, 0.8);
    border-color: #e95d80;
}

.modal-text {
    font-size: 16px;
    color: #6c757d;
}

